<template>
  <div class="px-4 py-3 bg--grey">
    <div class="form--group" :class="{ 'has-error': validation.hasError('fullname') }">
      <div class="col-form-label">{{ $t('mortgage.fullname') }}</div>
      <div>
        <input v-model="fullname" type="text" class="basic--input" name="fullname" />
        <span class="val-error" v-if="validation.hasError('fullname')">{{
          validation.firstError('fullname')
        }}</span>
      </div>
    </div>
    <!--    <div class="form&#45;&#45;group" :class="{ 'has-error': validation.hasError('dateOfBirth') }">-->
    <!--      <div class="col-form-label">{{ $t('mortgage.dateOfBirth') }}</div>-->
    <!--      <div>-->
    <!--        <input v-model="dateOfBirth" type="date" class="basic&#45;&#45;input" name="dateOfBirth" />-->
    <!--        <span class="val-error" v-if="validation.hasError('dateOfBirth')">{{-->
    <!--          validation.firstError('dateOfBirth')-->
    <!--        }}</span>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="form&#45;&#45;group" :class="{ 'has-error': validation.hasError('identityNumber') }">-->
    <!--      <div class="col-form-label">{{ $t('mortgage.identityNumber') }}</div>-->
    <!--      <div>-->
    <!--        <input v-model="identityNumber" type="text" class="basic&#45;&#45;input" name="identityNumber" />-->
    <!--        <span class="val-error" v-if="validation.hasError('identityNumber')">{{-->
    <!--          validation.firstError('identityNumber')-->
    <!--        }}</span>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="form&#45;&#45;group" :class="{ 'has-error': validation.hasError('address') }">-->
    <!--      <div class="col-form-label">{{ $t('mortgage.homeAddress') }}</div>-->
    <!--      <div>-->
    <!--        <textarea v-model="address" type="text" class="basic&#45;&#45;input" name="address" rows="3" />-->
    <!--        <span class="val-error" v-if="validation.hasError('address')">{{-->
    <!--          validation.firstError('address')-->
    <!--        }}</span>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="form--group" :class="{ 'has-error': validation.hasError('contactNumber') }">
      <div class="col-form-label">{{ $t('mortgage.contactNumber') }}</div>
      <div>
        <input v-model="contactNumber" type="text" class="basic--input" name="contactNumber" />
        <span class="val-error" v-if="validation.hasError('contactNumber')">{{
          validation.firstError('contactNumber')
        }}</span>
      </div>
    </div>
    <div class="form--group" :class="{ 'has-error': validation.hasError('email') }">
      <div class="col-form-label">{{ $t('mortgage.email') }}</div>
      <div>
        <input v-model="email" type="email" class="basic--input" name="email" />
        <span class="val-error" v-if="validation.hasError('email')">{{
          validation.firstError('email')
        }}</span>
      </div>
    </div>
    <div class="d-flex flex-wrap">
      <div
        class="form--group col-12 col-md-6 py-0"
        :class="{
          'p-0': $mq === 'xs',
          'pl-0': $mq !== 'xs',
          'has-error':
            validation.firstError('employmentStatus') ||
            validation.hasError('customEmploymentStatus'),
        }"
      >
        <div class="col-form-label">{{ $t('mortgage.employmentStatus') }}</div>
        <div>
          <multiselect
            v-model="employmentStatus"
            select-label=""
            deselect-label=""
            name="employmentStatus"
            track-by="id"
            label="name"
            :searchable="true"
            :close-on-select="true"
            :show-labels="true"
            :options="employmentStatuses"
            :placeholder="$t('mortgage.placeholder.employmentStatus')"
          >
          </multiselect>
          <span class="val-error" v-if="validation.hasError('employmentStatus')">{{
            validation.firstError('employmentStatus')
          }}</span>
          <div class="mt-2" v-if="employmentStatus && employmentStatus.id === null">
            <input
              v-model="customEmploymentStatus"
              type="text"
              placeholder="Isi Tipe Pekerjaan"
              class="basic--input"
              name="customEmploymentStatus"
            />
            <span class="val-error" v-if="validation.hasError('employmentStatus')">{{
              validation.firstError('customEmploymentStatus')
            }}</span>
          </div>
        </div>
      </div>
      <div
        class="form--group col-12 col-md-6 py-0"
        :class="{
          'p-0': $mq === 'xs',
          'pr-0': $mq !== 'xs',
          'has-error': validation.hasError('monthlyIncome'),
        }"
        v-if="employmentStatus && employmentStatus.id === 1"
      >
        <div class="col-form-label">{{ $t('mortgage.monthlyIncome') }}</div>
        <div>
          <cleave
            class="basic--input"
            name="monthlyIncome"
            v-model="monthlyIncome"
            :options="cleaveOptionsPrice"
          />
          <span class="val-error" v-if="validation.hasError('monthlyIncome')">{{
            validation.firstError('monthlyIncome')
          }}</span>
        </div>
      </div>
    </div>
    <div class="d-flex flex-wrap" v-if="employmentStatus && employmentStatus.id !== 1">
      <div
        class="form--group col-12 col-md-6 py-0"
        :class="{
          'p-0': $mq === 'xs',
          'pl-0': $mq !== 'xs',
          'has-error':
            validation.firstError('employmentStatus') ||
            validation.hasError('customEmploymentStatus'),
        }"
      >
        <div class="col-form-label">{{ $t('mortgage.monthlyIncomeMin') }}</div>
        <div>
          <cleave
            class="basic--input"
            name="monthlyIncomeMin"
            v-model="monthlyIncomeMin"
            :options="cleaveOptionsPrice"
          />
          <span class="val-error" v-if="validation.hasError('monthlyIncomeMin')">{{
            validation.firstError('monthlyIncomeMin')
          }}</span>
        </div>
      </div>
      <div
        class="form--group col-12 col-md-6 py-0"
        :class="{
          'p-0': $mq === 'xs',
          'pr-0': $mq !== 'xs',
          'has-error': validation.hasError('monthlyIncomeMax'),
        }"
      >
        <div class="col-form-label">{{ $t('mortgage.monthlyIncomeMax') }}</div>
        <div>
          <cleave
            class="basic--input"
            name="monthlyIncomeMax"
            v-model="monthlyIncomeMax"
            :options="cleaveOptionsPrice"
          />
          <span class="val-error" v-if="validation.hasError('monthlyIncomeMax')">{{
            validation.firstError('monthlyIncomeMax')
          }}</span>
        </div>
      </div>
    </div>
    <!--    <div class="form&#45;&#45;group" :class="{ 'has-error': validation.hasError('identityImage') }">-->
    <!--      <div class="col-form-label">{{ $t('mortgage.uploadIdentity') }}</div>-->
    <!--      <div class="d-flex u&#45;&#45;align-center">-->
    <!--        <div class="btn btn-primary" @click="uploadIdentity">{{ $t('mortgage.chooseFile') }}</div>-->
    <!--        <div class="ml-3" v-if="identityImage && identityImage.file">-->
    <!--          {{ identityImage.file.name }}-->
    <!--        </div>-->
    <!--        <div-->
    <!--          class="delete&#45;&#45;file ml-3"-->
    <!--          @click="removeIdentity"-->
    <!--          v-if="identityImage && identityImage.file"-->
    <!--        >-->
    <!--          {{ $t('addProject.btn.delete') }}-->
    <!--        </div>-->
    <!--        <vue-dropzone-->
    <!--          v-show="false"-->
    <!--          ref="identityDropzone"-->
    <!--          id="identityImage"-->
    <!--          :options="dropzoneOptions"-->
    <!--          @vdropzone-sending="sending"-->
    <!--          @vdropzone-success="success"-->
    <!--        />-->
    <!--        <span class="val-error ml-2" v-if="validation.hasError('identityImage')">{{-->
    <!--          validation.firstError('identityImage')-->
    <!--        }}</span>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--        <div class="form&#45;&#45;group" :class="{ 'has-error': validation.hasError('program') }">-->
    <!--          <div class="col-form-label">{{ $t('mortgage.program') }}</div>-->
    <!--          <div>-->
    <!--            &lt;!&ndash;        <input v-model="preferredBank" type="text" class="basic&#45;&#45;input" name="preferredBank" />&ndash;&gt;-->
    <!--            <multiselect-->
    <!--              v-model="program"-->
    <!--              select-label=""-->
    <!--              deselect-label=""-->
    <!--              name="program"-->
    <!--              track-by="id"-->
    <!--              label="title"-->
    <!--              :searchable="true"-->
    <!--              :close-on-select="true"-->
    <!--              :show-labels="true"-->
    <!--              :options="allPrograms"-->
    <!--              :placeholder="$t('mortgage.placeholder.program')"-->
    <!--              @input="calculateMortgage"-->
    <!--            >-->
    <!--            </multiselect>-->
    <!--            <span class="val-error" v-if="validation.hasError('program')">{{-->
    <!--              validation.firstError('program')-->
    <!--            }}</span>-->
    <!--          </div>-->
    <!--        </div>-->
    <div class="form--group" :class="{ 'has-error': validation.hasError('preferredBank') }">
      <div class="col-form-label">{{ $t('mortgage.bankPreference') }}</div>
      <div>
        <!--        <input v-model="preferredBank" type="text" class="basic&#45;&#45;input" name="preferredBank" />-->
        <multiselect
          v-model="preferredBank"
          select-label=""
          deselect-label=""
          name="employmentStatus"
          track-by="id"
          label="name"
          :searchable="true"
          :close-on-select="true"
          :show-labels="true"
          :options="allBanks"
          :placeholder="$t('mortgage.placeholder.preferredBank')"
        >
        </multiselect>
        <span class="val-error" v-if="validation.hasError('preferredBank')">{{
          validation.firstError('preferredBank')
        }}</span>
      </div>
    </div>
    <bank-info :bank="preferredBank"></bank-info>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { Validator } from 'simple-vue-validator';
// import vue2Dropzone from 'nuxt-dropzone';
const Cleave = () => import('vue-cleave-component');
import Multiselect from 'vue-multiselect';
import HelperMixin from '@/mixins/helpers';
const BankInfo = () => import('@/components/mortgage/bank-info');

require('vue-multiselect/dist/vue-multiselect.min.css');

export default {
  mixins: [HelperMixin],
  components: {
    Cleave,
    Multiselect,
    BankInfo,
    // vueDropzone: vue2Dropzone,
  },
  computed: {
    ...mapGetters({
      allPrograms: 'v2/mortgage/allPrograms',
      allBanks: 'v2/mortgage/allBanks',
    }),
    ...mapState({
      employmentStatuses: (state) => state.v2.mortgage.employmentStatuses,
      monthlyIncomeValue: (state) => state.v2.mortgage.monthlyIncomeValue,
      monthlyIncomeValueMin: (state) => state.v2.mortgage.monthlyIncomeValueMin,
      monthlyIncomeValueMax: (state) => state.v2.mortgage.monthlyIncomeValueMax,
    }),
    monthlyIncome: {
      get() {
        return this.monthlyIncomeValue;
      },
      set(val) {
        const monthlyIncomeValue = val
          ? typeof val == 'string'
            ? parseFloat(val.replace('Rp', '').trim())
            : val
          : 0;
        this.$store.commit('v2/mortgage/SET_MONTHLY_INCOME', monthlyIncomeValue);
      },
    },
    monthlyIncomeMin: {
      get() {
        return this.monthlyIncomeValueMin;
      },
      set(val) {
        const monthlyIncomeValue = val
          ? typeof val == 'string'
            ? parseFloat(val.replace('Rp', '').trim())
            : val
          : 0;
        this.$store.commit('v2/mortgage/SET_MONTHLY_INCOME_MIN', monthlyIncomeValue);
      },
    },
    monthlyIncomeMax: {
      get() {
        return this.monthlyIncomeValueMax;
      },
      set(val) {
        const monthlyIncomeValue = val
          ? typeof val == 'string'
            ? parseFloat(val.replace('Rp', '').trim())
            : val
          : 0;
        this.$store.commit('v2/mortgage/SET_MONTHLY_INCOME_MAX', monthlyIncomeValue);
      },
    },
  },
  data() {
    return {
      cleaveOptionsPrice: {
        numeral: true,
        numeralDecimalMark: ',',
        delimiter: '.',
        prefix: 'Rp ',
      },
      dropzoneOptions: {
        url: `${this.$store.state.global.apiUrl}/api/v2/file_content/upload_photo`,
        maxFilesize: 10,
        acceptedFiles: '.jpg,.png,.jpeg',
        paramName: 'file',
      },
      fullname: '',
      dateOfBirth: '',
      address: '',
      email: '',
      contactNumber: '',
      employmentStatus: null,
      customEmploymentStatus: '',
      identityImage: null,
      preferredBank: '',
      program: null,
    };
  },
  validators: {
    fullname(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.name.required'))
        .maxLength(255, this.$i18n.t('errors.name.max', { maxChar: 255 }));
    },
    // dateOfBirth(value) {
    //   return Validator.value(value).required(this.$i18n.t('errors.dateOfBirth.required'));
    // },
    // address(value) {
    //   return Validator.value(value)
    //     .required(this.$i18n.t('errors.address.required'))
    //     .maxLength(250, this.$i18n.t('errors.address.max', { maxChar: 250 }));
    // },
    email(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.email.required'))
        .maxLength(191, this.$i18n.t('errors.email.max', { maxChar: 191 }))
        .email(this.$i18n.t('errors.loginRegister.invalid'));
    },
    contactNumber(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.contactNumber.required'))
        .integer(this.$i18n.t('errors.contactNumber.number'));
    },
    employmentStatus(value) {
      return Validator.value(value).required(this.$i18n.t('errors.employmentStatus.required'));
    },
    customEmploymentStatus(value) {
      if (this.employmentStatus && this.employmentStatus.id === null) {
        return Validator.value(value).required(
          this.$i18n.t('errors.customEmploymentStatus.required'),
        );
      } else {
        return Validator.value(value);
      }
    },
    monthlyIncome(value) {
      if (this.employmentStatus && this.employmentStatus.id === 1) {
        return Validator.value(this.monthlyIncomeValue)
          .required(this.$i18n.t('errors.monthlyIncome.required'))
          .float(this.$i18n.t('errors.monthlyIncome.number'))
          .greaterThan(0, this.$i18n.t('errors.monthlyIncome.greaterThan'));
      } else {
        return Validator.value(value);
      }
    },
    monthlyIncomeMin(value) {
      if (this.employmentStatus && this.employmentStatus.id !== 1) {
        return Validator.value(this.monthlyIncomeValueMin)
          .required(this.$i18n.t('errors.monthlyIncome.required'))
          .float(this.$i18n.t('errors.monthlyIncome.number'))
          .greaterThan(0, this.$i18n.t('errors.monthlyIncome.greaterThan'));
      } else {
        return Validator.value(value);
      }
    },
    monthlyIncomeMax(value) {
      if (this.employmentStatus && this.employmentStatus.id !== 1) {
        return Validator.value(this.monthlyIncomeValueMax)
          .required(this.$i18n.t('errors.monthlyIncome.required'))
          .float(this.$i18n.t('errors.monthlyIncome.number'))
          .greaterThan(0, this.$i18n.t('errors.monthlyIncome.greaterThan'));
      } else {
        return Validator.value(value);
      }
    },
    preferredBank(value) {
      return Validator.value(value);
      // return Validator.value(value).required(this.$i18n.t('errors.preferredBank.required'));
    },
  },
  methods: {
    validate: function () {
      this.$store.commit('v2/mortgage/SET_FULLNAME', this.cleanInput(this.fullname));
      this.$store.commit('v2/mortgage/SET_DATE_BIRTH', this.dateOfBirth);
      this.$store.commit('v2/mortgage/SET_ADDRESS', this.address);
      this.$store.commit('v2/mortgage/SET_EMAIL', this.cleanInput(this.email));
      this.$store.commit('v2/mortgage/SET_CONTACT_NUMBER', this.cleanInput(this.contactNumber));
      this.$store.commit('v2/mortgage/SET_EMPLOYMENT_STATUS', this.employmentStatus);
      this.$store.commit('v2/mortgage/SET_CUSTOM_EMPLOYMENT_STATUS', this.customEmploymentStatus);
      this.$store.commit('v2/mortgage/SET_IDENTITY_IMAGE', this.identityImage);
      this.$store.commit('v2/mortgage/SET_PREFERRED_BANK', this.preferredBank);
      return this.$validate();
    },
    uploadIdentity() {
      this.$refs.identityDropzone.dropzone.hiddenFileInput.click();
    },
    removeIdentity() {
      this.identityImage = null;
    },
    // eslint-disable-next-line no-unused-vars
    sending(file, xhr, formData) {
      xhr.setRequestHeader('Authorization', `Bearer ${this.$store.state.global.token}`);
    },
    success(file, response) {
      if (response.type === 'success') {
        console.log('SUCCESS UPLOAD NRIC!', response.data);
        this.identityImage = {
          file: file,
          uuid: response.data.photo_uuid,
        };
      }
    },
    calculateMortgage() {},
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/utils/variables';
.form--group {
  display: block;
  .val-error {
    margin-top: 8px;
  }
}
.bank--preference {
  background-color: $color-white;
  border-radius: 0.5rem;

  img {
    width: 100%;
    max-height: 50px;
    object-fit: contain;
    margin: auto;
    padding: 0.5rem;
  }

  .custom-checkbox {
    display: flex;
    align-items: center;

    .custom-control-label {
      width: 100%;

      &::before {
        top: calc(50% - 8px);
      }

      &::after {
        top: calc(50% - 8px);
      }
    }
  }

  .bank--name {
    font-size: $font-md;
  }

  .bank--description {
    font-size: $font-sm;
    color: darkgrey;
  }
}

.delete--file {
  color: red;
  cursor: pointer;
}
</style>
